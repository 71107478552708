import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-awesome-styled-grid"
import styled from "styled-components"
import { space } from "styled-system"

import {
  Layout,
  SEO,
  LeadText,
  ProjectsList,
  ProjectCarousel,
  H2,
} from "../components"

const GreyArea = styled.div`
  background-color: #f8f8f7;
  text-align: center;
  ${space};
`

const MoreWorkWrapper = styled.div`
  text-align: center;
  ${space};
  & > a {
    color: #232222;
    text-decoration: none;
  }
  & > a img,
  & > a p {
    transition: opacity 0.2s ease-in-out;
  }
  & > a:hover img,
  & > a:hover p {
    opacity: 0.6;
  }
  & > a > p {
    margin-top: 24px;
    font-family: "Engravers Gothic";
    font-size: 15px;
    letter-spacing: 1.8px;
    color: #232222;
  }
`

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const projects = data.allMarkdownRemark.nodes
  const featuredProjects = projects.filter(
    project => project.frontmatter.featured
  )
  const filteredBannerProjects = projects.filter(
    project => project.frontmatter.featuredBanner
  )

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="We are 327" image="/studio.jpg" />
      <ProjectCarousel projects={filteredBannerProjects} />
      <LeadText
        title="Details matter"
        text="By creating remarkable brands and telling their story, we strive to work towards an engaging and meaningful design experience."
      />
      <Container>
        <ProjectsList projects={featuredProjects} />
        <MoreWorkWrapper mt={[4, 4, 5]}>
          <Link to="work">
            <img src="/more-work.svg" alt="" />
            <p>more work</p>
          </Link>
        </MoreWorkWrapper>
      </Container>
      <GreyArea py={[5, 6]} mt={5}>
        <Container>
          <Row>
            <Col xs={10} md={8} offset={{ xs: 1, md: 2 }}>
              <H2 as="p" style={{ fontSize: "45px" }}>
                We can’t save the world, but at least we can make it goddamn
                beautiful.
              </H2>
            </Col>
          </Row>
        </Container>
      </GreyArea>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          featured
          featuredBanner
          contrastColor
          extra
          tags
          card {
            publicURL
          }
          featuredCover {
            publicURL
          }
          featuredCoverMobile {
            publicURL
          }
        }
      }
    }
  }
`
